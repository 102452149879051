import React from "react";
import { Result, Button } from "antd";
import Header from "../components/Header";

const Page404 = (props) => (
  <>
    {props.type === "404" ? (
      <>
        <Header />
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button type="primary" onClick={() => (window.location = "/")}>
              Back Home
            </Button>
          }
        />
      </>
    ) : (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you don't have the permission to access this route."
        extra={
          <Button type="primary" onClick={() => (window.location = "/")}>
            Back Home
          </Button>
        }
      />
    )}
  </>
);
export default Page404;
