import React from "react";
import Axios from "axios";
import { Modal, Button, Space, Select, Popconfirm } from "antd";
import { Switch, Form, Input, Row } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import cogoToast from "cogo-toast";
import { useEffect } from "react";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 9, offset: 1 },
    lg: { span: 9, offset: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 12 },
    lg: { span: 12 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
    md: { span: 12, offset: 6 },
    lg: { span: 7, offset: 7 },
  },
};

const api_url = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token_mg_WB");

const BatchModal = (props) => {
  const { addVisible, setAddVisible } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    const { title } = values;
    if (title) {
      let data = { title };
      Axios.post(`${api_url}/cb/batch`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          const { error } = response.data;
          if (error) {
            cogoToast.error("Server error", { position: "top-right" });
          } else {
            cogoToast
              .success("Batch Created", { position: "top-right" })
              .then(() => {
                setLoading(false);
                setAddVisible(false);
                window.location = "/";
              });
          }
        })
        .catch(() => {
          cogoToast.error("Error in creating batch, please try again", {
            position: "top-right",
          });
          setLoading(false);
        });
    } else {
      cogoToast.error("No title provided", { position: "top-right" });
    }
  };

  return (
    <Modal
      visible={addVisible}
      footer={null}
      title="Create Batch"
      onCancel={() => {
        setAddVisible(false);
      }}
    >
      <Form {...formItemLayout} form={form} onFinish={onFinish}>
        <Form.Item label="Title" name="title">
          <Input />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Space size="large">
            <Button
              htmlType="submit"
              size="large"
              loading={loading}
              type="primary"
            >
              Create New Batch
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const AddStudents = (props) => {
  const { addVisible, setAddVisible } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [sCourse, setSCourse] = useState(false);
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [bulk, setBulk] = useState(false);
  const [late, setLate] = useState(false);
  const [sfile, setsfile] = useState();

  useEffect(() => {
    Axios.get(`${api_url}/cb/course`, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      const { error, courses } = response.data;
      if (error)
        cogoToast.error("Could not load courses", { position: "top-right" });
      else setCourses(courses);
    });
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    let formdata = new FormData();
    let url = `${api_url}/auth/register/`;
    if (bulk) {
      url += "bulk/";
      if (late) url += "late";
      if (sfile) {
        if (sfile.name.endsWith(".csv")) {
          const { course, batch } = values;
          formdata.append("users", sfile, sfile.name);
          formdata.append("batch", batch);
          formdata.append("course", course);
          Axios.post(url, formdata, {
            headers: { Authorization: `Bearer ${token}` },
          }).then((response) => {
            const { error } = response.data;
            if (error) cogoToast.error("Error", { position: "top-right" });
            else cogoToast.success("Users created", { position: "top-right" });
          });
        } else cogoToast.error("Only CSV files", { position: "top-right" });
        setLoading(false);
      }
    } else {
      url += "user/";
      if (late) url += "late";
      const { name, phone, email, moodleID, batch, course } = values;
      Axios.post(
        url,
        { name, phone, email, moodleID, batch, course },
        { headers: { Authorization: `Bearer ${token}` } }
      ).then((response) => {
        const { error } = response.data;
        if (error) cogoToast.error("Error", { position: "top-right" });
        else cogoToast.success("User registered", { position: "top-right" });
        setLoading(false);
      });
    }
    setAddVisible(false);
  };

  return (
    <Modal
      visible={addVisible}
      footer={null}
      title="Add Students to Batch"
      onCancel={() => {
        setAddVisible(false);
      }}
    >
      <Form {...formItemLayout} form={form} onFinish={onFinish}>
        <Form.Item label="Course" name="course">
          <Select
            onChange={(e) => {
              setSCourse(false);
              console.log(e.valueOf());
              const c = courses.find((cx) => cx._id === e.valueOf());
              setBatches(c.batches);
              setSCourse(true);
            }}
          >
            {courses
              ? courses.map((course) => {
                  return (
                    <Select.Option key={course._id} value={course._id}>
                      {course.title}
                    </Select.Option>
                  );
                })
              : null}
          </Select>
        </Form.Item>
        <Form.Item label="Batch" name="batch">
          <Select>
            {batches && sCourse
              ? batches.map((batch) => {
                  return (
                    <Select.Option key={batch._id} value={batch._id}>
                      {batch.title}
                    </Select.Option>
                  );
                })
              : null}
          </Select>
        </Form.Item>
        <Form.Item label="Bulk">
          <Switch
            defaultChecked={bulk}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={(e) => setBulk(e.valueOf())}
          />
        </Form.Item>
        <Form.Item label="Late">
          <Switch
            defaultChecked={late}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={(e) => setLate(e.valueOf())}
          />
        </Form.Item>
        {bulk ? (
          <>
            <Form.Item label="CSV File">
              <Input
                type="file"
                onChange={(e) => setsfile(e.target.files[0])}
              />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item label="Name" name="name">
              <Input />
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input type="email" />
            </Form.Item>
            <Form.Item label="Phone" name="phone">
              <Input />
            </Form.Item>
            <Form.Item label="Moodle ID" name="moodleID">
              <Input />
            </Form.Item>
          </>
        )}
        <Form.Item {...tailFormItemLayout}>
          <Space size="large">
            <Button
              htmlType="submit"
              size="large"
              loading={loading}
              type="primary"
            >
              Update Batch
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Search = (props) => {
  const { addVisible, setAddVisible } = props;
  const [data, setData] = useState();
  const [email, setEmail] = useState("");

  const onSearch = async (email) => {
    const { data } = await Axios.get(`${api_url}/auth/users`, {
      params: { email },
      headers: { Authorization: `Bearer ${token}` },
    });
    const { error, user, batch, course } = data;
    if (error) cogoToast.error("User not found");
    else setData({ user, batch, course });
  };

  const onDelete = async (email) => {
    const { data } = await Axios.delete(`${api_url}/auth/user`, {
      params: { email },
      headers: { Authorization: `Bearer ${token}` },
    });
    const { error, message } = data;
    if (error) cogoToast.error("User couldn't be deleted");
    else {
      cogoToast.success(message);
      setAddVisible(false);
    }
  };

  return (
    <Modal
      title="Search"
      visible={addVisible}
      footer={null}
      onCancel={() => {
        setAddVisible(false);
      }}
    >
      <Space>
        <Input
          width={300}
          placeholder="Email"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button type="primary" onClick={() => onSearch(email)}>
          Search
        </Button>
      </Space>
      <br />
      {data ? (
        <>
          <Row style={{ marginTop: "5%", marginLeft: "3%" }}>
            <Form>
              <Form.Item label="Name">{data.user?.name}</Form.Item>
              <Form.Item label="Email">{data.user?.email}</Form.Item>
              <Form.Item label="MoodleID">{data.user?.moodleID}</Form.Item>
              <Form.Item label="Batch">{data.batch?.title}</Form.Item>
              <Form.Item label="Course">{data.course?.title}</Form.Item>
            </Form>
          </Row>
          <Row justify="center">
            <Popconfirm
              onConfirm={() => {
                onDelete(data.user?.email);
              }}
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger">Delete Student</Button>
            </Popconfirm>
          </Row>
        </>
      ) : null}
    </Modal>
  );
};

export { BatchModal, AddStudents, Search };
