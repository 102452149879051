import { SearchOutlined } from "@ant-design/icons";
import { Button, Row, Space } from "antd";
import Axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { BatchModal, Search, AddStudents } from "./modal";
import Table from "./table";

const api = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token_mg_WB");

const BatchTab = (props) => {
  const [addVisible, setAddVisible] = useState(false);
  const [addVisible2, setAddVisible2] = useState(false);
  const [addVisible3, setAddVisible3] = useState(false);
  const [users, setUsers] = useState(0);
  useEffect(() => {
    Axios.get(`${api}/auth/users`, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      const { error, users } = response.data;
      if (!error) setUsers(users);
    });
  }, []);

  return (
    <>
      <BatchModal addVisible={addVisible} setAddVisible={setAddVisible} />
      <AddStudents addVisible={addVisible2} setAddVisible={setAddVisible2} />
      <Search addVisible={addVisible3} setAddVisible={setAddVisible3} />
      <Row justify="center">
        <Space size="large">
          <Button type="primary" onClick={() => setAddVisible(true)}>
            Add Batch
          </Button>
          <Button type="danger" onClick={() => setAddVisible2(true)}>
            Add Students to Batch
          </Button>
          <Button
            icon={<SearchOutlined />}
            onClick={() => setAddVisible3(true)}
          >
            Search Student
          </Button>
        </Space>
      </Row>
      <Row style={{ marginTop: "2%" }} justify="center">
        <h3>Total Users : {users}</h3>
      </Row>
      <Table />
    </>
  );
};

export default BatchTab;
