import React from "react";
import cogoToast from "cogo-toast";
import { Layout, Menu } from "antd";
import { useHistory } from "react-router-dom";

const token = localStorage.getItem("token_mg_WB");

const Header = (props) => {
  const history = useHistory();
  return (
    <Layout className="layout">
      <Layout.Header>
        <Menu theme="dark" mode="horizontal">
          <Menu.Item onClick={() => history.push("/")}>
            <div>
              <img
                alt="logo-mathongo"
                style={{ width: "45%" }}
                src="https://cdn1.mathongo.com/wp-content/uploads/20190616134025/mathongo_full_logo_light.svg"
              />
            </div>
          </Menu.Item>
          {token ? (
            <Menu.Item
              style={{ float: "right" }}
              onClick={() => {
                localStorage.removeItem("token_mg_WB");
                cogoToast
                  .success("Logging Out", {
                    position: "top-right",
                  })
                  .then(() => {
                    window.location = "/";
                  });
              }}
            >
              Logout
            </Menu.Item>
          ) : null}
        </Menu>
      </Layout.Header>
    </Layout>
  );
};

export default Header;
