import { Spin, Table, Button } from "antd";
import React, { useEffect, useState } from "react";
import Axios from "axios";
import cogoToast from "cogo-toast";
import BatchInfo from "./info";
import fileDownload from "js-file-download";

const token = localStorage.getItem("token_mg_WB");
const api = process.env.REACT_APP_API_URL;

const TableCourse = (props) => {
  const [loading, setLoading] = useState(true);
  const [infoVisible, setInfoVisible] = useState(false);
  const [batches, setBatches] = useState([]);
  const [cBatch, setCBatch] = useState();

  const getAttendance = async (batch_id) => {
    const { data } = await Axios.get(`${api}/cb/batch/attendance`, {
      params: { batch_id },
      headers: { Authorization: `Bearer ${token}` },
    });
    fileDownload(data, "attendance.csv");
  };

  const columns = [
    { title: "ID", dataIndex: "_id", key: "_id" },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Info",
      dataIndex: "_id",
      key: "_id",
      render: (key, obj) => (
        <Button
          type="danger"
          onClick={() => {
            setCBatch(obj);
            setInfoVisible(true);
          }}
        >
          View Info
        </Button>
      ),
    },
    {
      title: "Attendance",
      dataIndex: "_id",
      key: "_id",
      render: (key) => (
        <Button
          type="primary"
          onClick={() => {
            getAttendance(key);
          }}
        >
          Get Attendance
        </Button>
      ),
    },
  ];
  useEffect(() => {
    Axios.get(`${api}/cb/batch`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      const { error, batches } = response.data;
      if (error) {
        cogoToast.error("Error loading classes", { position: "top-right" });
      } else {
        setBatches(batches);
        setLoading(false);
      }
    });
  }, []);
  return (
    <>
      {loading ? (
        <Spin
          style={{ marginTop: "3%", display: "flex", justifyContent: "center" }}
        />
      ) : (
        <>
          {infoVisible ? (
            <BatchInfo
              infoVisible={infoVisible}
              setInfoVisible={setInfoVisible}
              cBatch={cBatch}
            />
          ) : null}
          <Table
            rowKey="_id"
            dataSource={batches}
            columns={columns}
            pagination={{
              defaultPageSize: 100,
              position: ["topRight"],
              total: batches.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} batches`,
            }}
          />
        </>
      )}
    </>
  );
};

export default TableCourse;
