import { Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import Axios from "axios";
import Header from "../components/Header";
import Page404 from "./404";
import cogoToast from "cogo-toast";
import { useHistory } from "react-router-dom";

import ClassTab from "../components/classTab";
import CoursesTab from "../components/courseTab";
import BatchTab from "../components/batchTab";

const token = localStorage.getItem("token_mg_WB");
const api = process.env.REACT_APP_API_URL;

const Home = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    if (token) {
      Axios.get(`${api}/auth/check`, {
        headers: { Authorization: `Bearer ${token}` },
      }).then((response) => {
        const { error, isAdmin } = response.data;
        if (error)
          cogoToast.error("Please login again", { position: "top-right" });
        else {
          if (!isAdmin) history.push("/login");
          else {
            setIsAdmin(isAdmin);
            setLoading(false);
          }
        }
      });
    } else {
      setIsAdmin(false);
      setLoading(false);
      history.push("/login");
    }
  }, [history]);

  return (
    <>
      {loading ? (
        <Spin
          style={{ marginTop: "4%", display: "flex", justifyContent: "center" }}
        />
      ) : isAdmin ? (
        <>
          <Header />
          <div
            style={{
              marginTop: "4%",
              marginLeft: "2%",
              marginRight: "2%",
            }}
          >
            <Tabs>
              <Tabs.TabPane key="1" tab="Manage Classes">
                <ClassTab />
              </Tabs.TabPane>
              <Tabs.TabPane key="2" tab="Manage Courses">
                <CoursesTab />
              </Tabs.TabPane>
              <Tabs.TabPane key="3" tab="Manage Batches">
                <BatchTab />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </>
      ) : (
        <Page404 type="403" />
      )}
    </>
  );
};

export default Home;
