import React from "react";
import { Modal, Table, Row, Spin, Col } from "antd";
import { useEffect, useState } from "react";
import Axios from "axios";
import cogoToast from "cogo-toast";

const api = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token_mg_WB");

const CourseInfo = (props) => {
  const { cCourse, infoVisible, setInfoVisible } = props;
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState();

  useEffect(() => {
    setLoading(true);
    Axios.get(`${api}/cb/course`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { course_id: cCourse._id },
    }).then((response) => {
      const { error, course } = response.data;
      if (error)
        cogoToast.error("Could not load details", { position: "top-right" });
      else setCourse(course);
      setLoading(false);
    });
  }, [cCourse]);

  const columns = [
    { title: "ID", dataIndex: "_id", key: "_id" },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
  ];
  return (
    <Modal
      width={800}
      footer={null}
      visible={infoVisible}
      onCancel={() => {
        setInfoVisible(false);
      }}
    >
      {loading ? (
        <Spin
          style={{ marginTop: "2%", display: "flex", justifyContent: "center" }}
        />
      ) : (
        <div style={{ margin: "2%" }}>
          <Row>
            <h3>{course.title}</h3>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <Table columns={columns} dataSource={course.batches} />
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );
};

export default CourseInfo;
