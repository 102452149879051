import React from "react";
import Axios from "axios";
import { Modal, Button, Space, Select } from "antd";
import { Form, Input } from "antd";
import { useState } from "react";
import cogoToast from "cogo-toast";
import { useEffect } from "react";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 9, offset: 1 },
    lg: { span: 9, offset: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 12 },
    lg: { span: 12 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
    md: { span: 12, offset: 6 },
    lg: { span: 7, offset: 7 },
  },
};

const api_url = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token_mg_WB");

const CourseModal = (props) => {
  const { addVisible, setAddVisible } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    const { title } = values;
    if (title) {
      let data = { title };
      Axios.post(`${api_url}/cb/course`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          const { error } = response.data;
          if (error) {
            cogoToast.error("Course Created", { position: "top-right" });
          } else {
            cogoToast
              .success("Course Created", { position: "top-right" })
              .then(() => {
                setLoading(false);
                setAddVisible(false);
                window.location = "/";
              });
          }
        })
        .catch(() => {
          cogoToast.error("Error in creating course, please try again", {
            position: "top-right",
          });
          setLoading(false);
        });
    } else {
      cogoToast.error("No title provided", { position: "top-right" });
    }
  };

  return (
    <Modal
      visible={addVisible}
      footer={null}
      title="Create Course"
      onCancel={() => {
        setAddVisible(false);
      }}
    >
      <Form {...formItemLayout} form={form} onFinish={onFinish}>
        <Form.Item label="Title" name="title">
          <Input />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Space size="large">
            <Button
              htmlType="submit"
              size="large"
              loading={loading}
              type="primary"
            >
              Create New Course
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const AddBatchModal = (props) => {
  const { addVisible, setAddVisible } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);

  useEffect(() => {
    Axios.get(`${api_url}/cb/batch`, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      const { error, batches } = response.data;
      if (error)
        cogoToast.error("Could not load batches", { position: "top-right" });
      else setBatches(batches);
    });
    Axios.get(`${api_url}/cb/course`, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      const { error, courses } = response.data;
      if (error)
        cogoToast.error("Could not load courses", { position: "top-right" });
      else setCourses(courses);
    });
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    const { course_id, batch } = values;
    const batches = batch.join();
    let data = { course_id, batches };
    Axios.patch(`${api_url}/cb/course`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const { error } = response.data;
        if (error) {
          cogoToast.error("Course could not be updated", {
            position: "top-right",
          });
        } else {
          cogoToast
            .success("Course updated with batches", { position: "top-right" })
            .then(() => {
              setLoading(false);
              setAddVisible(false);
              window.location = "/";
            });
        }
      })
      .catch(() => {
        cogoToast.error("Error in updating course, please try again", {
          position: "top-right",
        });
        setLoading(false);
      });
  };

  return (
    <Modal
      visible={addVisible}
      footer={null}
      title="Add Batches to Course"
      onCancel={() => {
        setAddVisible(false);
      }}
    >
      <Form {...formItemLayout} form={form} onFinish={onFinish}>
        <Form.Item label="Course" name="course_id">
          <Select>
            {courses.map((course) => {
              return (
                <Select.Option key={course._id} value={course._id}>
                  {course.title}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Batches" name="batch">
          {batches ? (
            <Select mode="multiple">
              {batches.map((batch) => {
                return (
                  <Select.Option key={batch._id} value={batch._id}>
                    {batch.title}
                  </Select.Option>
                );
              })}
            </Select>
          ) : null}
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Space size="large">
            <Button
              htmlType="submit"
              size="large"
              loading={loading}
              type="primary"
            >
              Update Course
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { CourseModal, AddBatchModal };
