import React from "react";
import { Modal, Table, Row, Spin, Col, Tooltip } from "antd";
import { useEffect, useState } from "react";
import Axios from "axios";
import cogoToast from "cogo-toast";
import moment from "moment";

const api = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token_mg_WB");

const BatchInfo = (props) => {
  const { cBatch, infoVisible, setInfoVisible } = props;
  const [loading, setLoading] = useState(true);
  const [login, setLogin] = useState(false);
  const [loginInfo, setLoginInfo] = useState();
  const [batch, setBatch] = useState();

  useEffect(() => {
    setLoading(true);
    Axios.get(`${api}/cb/batch`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { batch_id: cBatch._id },
    }).then((response) => {
      const { error, batch } = response.data;
      if (error)
        cogoToast.error("Could not load details", { position: "top-right" });
      else setBatch(batch);
      setLoading(false);
    });
  }, [cBatch]);

  const columns = [
    { title: "ID", dataIndex: "_id", key: "_id" },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
    { title: "MoodleID", dataIndex: "moodleID", key: "moodleID" },
    {
      title: "Logins",
      dataIndex: "logins",
      key: "logins",
      defaultSortOrder: "descend",
      render: (key) => <span>{key ? key.length : "-"}</span>,
      sorter: (a, b) => a.logins.length - b.logins.length,
    },
  ];

  const columns2 = [
    { title: "ID", dataIndex: "_id", key: "_id" },
    { title: "Course ID", dataIndex: "courseID", key: "courseID" },
    { title: "Moodle ID", dataIndex: "moodleID", key: "moodleID" },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (key) => (
        <Tooltip title={moment(key).format("llll")}>
          <span>{moment(key).fromNow()}</span>
        </Tooltip>
      ),
    },
    {
      title: "IP",
      dataIndex: "geoDetails",
      key: "geoDetails",
      render: (key) => <span>{key.ip}</span>,
    },
    {
      title: "City",
      dataIndex: "geoDetails",
      key: "geoDetails",
      render: (key) => <span>{key.city}</span>,
    },
    {
      title: "Region",
      dataIndex: "geoDetails",
      key: "geoDetails",
      render: (key) => <span>{key.region}</span>,
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      render: (key) => <span>{key.description}</span>,
    },
  ];

  return (
    <>
      <Modal
        title="Login Instances"
        width={1400}
        style={{ height: 600 }}
        footer={null}
        visible={login}
        onCancel={() => setLogin(false)}
      >
        {loginInfo ? (
          <Table dataSource={loginInfo.logins} columns={columns2} />
        ) : null}
      </Modal>
      <Modal
        title={batch?.title}
        width={800}
        footer={null}
        visible={infoVisible}
        onCancel={() => setInfoVisible(false)}
      >
        {loading ? (
          <Spin
            style={{
              marginTop: "2%",
              display: "flex",
              justifyContent: "center",
            }}
          />
        ) : (
          <div style={{ margin: "2%" }}>
            <Row>
              <Col span={24}>
                <Table
                  size="small"
                  columns={columns}
                  dataSource={batch.students}
                  pagination={{
                    defaultPageSize: 100,
                    position: ["topRight"],
                    total: batch.students.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} students`,
                  }}
                  onRow={(record) => {
                    return {
                      onDoubleClick: () => {
                        setLoginInfo(record);
                        setLogin(true);
                      },
                    };
                  }}
                />
              </Col>
            </Row>
          </div>
        )}
      </Modal>
    </>
  );
};

export default BatchInfo;
