import { Button, Row, Space } from "antd";
import React, { useState } from "react";
import { CourseModal, AddBatchModal } from "./modal";
import Table from "./table";

const CourseTab = (props) => {
  const [addVisible, setAddVisible] = useState(false);
  const [addVisible2, setAddVisible2] = useState(false);
  return (
    <>
      <CourseModal addVisible={addVisible} setAddVisible={setAddVisible} />
      <AddBatchModal addVisible={addVisible2} setAddVisible={setAddVisible2} />
      <Row justify="center">
        <Space size="large">
          <Button type="primary" onClick={() => setAddVisible(true)}>
            Add Course
          </Button>
          <Button type="danger" onClick={() => setAddVisible2(true)}>
            Add Batches to Course
          </Button>
        </Space>
      </Row>
      <Table />
    </>
  );
};

export default CourseTab;
