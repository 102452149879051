import React, { useState } from "react";
import Axios from "axios";
import cogoToast from "cogo-toast";
import { Form, Input, Button, Space } from "antd";
import Header from "../components/Header";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 6, offset: 1 },
    lg: { span: 4, offset: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 12 },
    lg: { span: 5 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
    md: { span: 12, offset: 6 },
    lg: { span: 8, offset: 8 },
  },
};

const Login = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    const { email, password } = values;
    setLoading(true);
    Axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
      email,
      password,
    }).then((response) => {
      const { error, token } = response.data;
      if (error) {
        cogoToast.error("Invalid Credentials", { position: "top-right" });
        setLoading(false);
      } else {
        localStorage.setItem("token_mg_WB", token);
        setLoading(false);
        cogoToast
          .success("Logged In, Redirecting", { position: "top-right" })
          .then(() => {
            window.location = "/";
          });
      }
    });
  };

  return (
    <div>
      <Header />
      <div style={{ margin: "3%", textAlign: "center" }}>
        <h1 className="heading">Login</h1>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailFormItemLayout} style={{ marginTop: "3%" }}>
            <Space>
              <Button
                size="large"
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                Login
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
