import { Spin, Table, Button, Popconfirm } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import cogoToast from 'cogo-toast';
import moment from 'moment';

const token = localStorage.getItem('token_mg_WB');
const api = process.env.REACT_APP_API_URL;

const TableClass = (props) => {
  const { withSMS, setClassId, setEndVisible } = props;
  const [loading, setLoading] = useState(true);
  const [classes, setClasses] = useState([]);

  const sendN = (class_id, sms = true) => {
    let url = `${api}/class/notify`;
    if (!sms) url += '/noSMS';
    Axios.post(url, null, {
      params: { class_id },
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      const { error, message } = response.data;
      if (error)
        cogoToast.error('Error in sending notifications', {
          position: 'top-right',
        });
      else {
        cogoToast.success(message, { position: 'top-right' }).then(() => {
          window.location = '/';
        });
      }
    });
  };

  const endC = (class_id) => {
    setClassId(class_id);
    setEndVisible(true);
  };

  const checkC = (class_id) => {
    Axios.get(`${api}/class/check`, {
      params: { class_id },
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      const { error, message } = response.data;
      if (error)
        cogoToast.error('Error while checking class. Try again', {
          position: 'top-right',
        });
      else {
        cogoToast.success(message, { position: 'top-right' });
      }
    });
  };

  const columns = [
    { title: 'ID', dataIndex: '_id', key: '_id' },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    { title: 'Course', dataIndex: 'course', key: 'course', render: (course) => course.title },
    {
      title: 'Batches',
      dataIndex: 'batches',
      key: 'batches',
      render: (batches) =>
        batches.reduce((curr, next, idx) => {
          if (idx !== 0) return curr + ', ' + next.title;
          return next.title;
        }, ''),
    },
    { title: 'Duration', dataIndex: 'duration', key: 'duration' },
    { title: 'Zoom ID', dataIndex: 'classID', key: 'classID' },
    {
      title: 'Date/Time',
      dataIndex: 'dateTime',
      key: 'dateTime',
      defaultSortOrder: 'descend',
      render: (key) => <span>{moment(key).subtract('5.5', 'h').format('DD/MM/YY hh:mm:ss a')}</span>,
      sorter: (a, b) => moment(a.dateTime) - moment(b.dateTime),
    },
    {
      title: 'Notified',
      dataIndex: 'notified',
      key: 'notified',
      render: (key) => {
        if (key) return <CheckCircleFilled style={{ color: '#52c41a' }} />;
        else return <CloseCircleFilled style={{ color: '#eb2f96' }} />;
      },
    },
    {
      title: 'Send Notification',
      dataIndex: '_id',
      key: '_id',
      render: (key, obj) => {
        if (key)
          return (
            <Popconfirm
              disabled={obj.ended}
              title="Are you sure?"
              onConfirm={() => (withSMS ? sendN(key) : sendN(key, false))}
            >
              <Button disabled={obj.ended} type="primary">
                Send Notification
              </Button>
            </Popconfirm>
          );
      },
    },
    {
      title: 'Check Registrants',
      dataIndex: '_id',
      key: '_id',
      render: (key, obj) => {
        if (key)
          return (
            <Popconfirm disabled={obj.ended} title="Are you sure?" onConfirm={() => checkC(key)}>
              <Button disabled={obj.ended}>Check Registrants</Button>
            </Popconfirm>
          );
      },
    },
    {
      title: 'End Class',
      dataIndex: '_id',
      key: '_id',
      render: (key, obj) => {
        return (
          <Button onClick={() => endC(key)} disabled={obj.ended} type="danger">
            End Class
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    Axios.get(`${api}/class`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      const { error, classes } = response.data;
      if (error) {
        cogoToast.error('Error loading classes', { position: 'top-right' });
      } else {
        setClasses(classes);
        setLoading(false);
      }
    });
  }, []);

  return (
    <>
      {loading ? (
        <Spin style={{ display: 'flex', justifyContent: 'center' }} />
      ) : (
        <Table
          rowKey="_id"
          dataSource={classes}
          columns={columns}
          pagination={{
            defaultPageSize: 100,
            position: ['topRight'],
            total: classes.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} classes`,
          }}
        ></Table>
      )}
    </>
  );
};

export default TableClass;
