import React, { useState } from "react";
import Table from "./table";
import { Row, Button, Switch, Space } from "antd";
import { ClassModal, EndModal, LinkModal } from "./modal";

const ClassTab = (props) => {
  const [withSMS, setWithSMS] = useState(false);
  const [classId, setClassId] = useState(null);
  const [formVisible, setFormVisible] = useState(false);
  const [linkVisible, setLinkVisible] = useState(false);
  const [endVisible, setEndVisible] = useState(false);
  return (
    <>
      <Row style={{ marginBottom: "3%" }} justify="center">
        <Space size="large">
          <Button type="primary" onClick={() => setFormVisible(true)}>
            Create New Class
          </Button>
          <Button type="danger" onClick={() => setLinkVisible(true)}>
            Search Link
          </Button>
          <span>
            <b>SMS Notifications:</b>
          </span>
          <Switch
            checked={withSMS}
            onChange={(e) => setWithSMS(e.valueOf())}
          ></Switch>
        </Space>
      </Row>
      <ClassModal formVisible={formVisible} setFormVisible={setFormVisible} />
      <LinkModal linkVisible={linkVisible} setLinkVisible={setLinkVisible} />
      <EndModal
        endVisible={endVisible}
        setEndVisible={setEndVisible}
        classId={classId}
      />
      <Table
        withSMS={withSMS}
        setClassId={setClassId}
        setEndVisible={setEndVisible}
      />
    </>
  );
};

export default ClassTab;
